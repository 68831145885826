import React from "react"
import IntegrationTemplate from "../../templates/integration"
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="alibaba"
    name="Alibaba Cloud Storage"
    shortDescription="Save your backups on Alibaba Cloud Storage."
    website={"https://www.alibabacloud.com/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="alibaba-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Alibaba Storage')}
    metaDescription={meta.description.replace('##provider##', 'Alibaba Storage')}
  >
    <IntegrationStorageContent service="Alibaba Cloud Storage" provider="Alibaba"/>
  </IntegrationTemplate>
)

export default ContentPage
